import { render, staticRenderFns } from "./HerdView.vue?vue&type=template&id=49d931ac&scoped=true&"
import script from "./HerdView.vue?vue&type=script&lang=js&"
export * from "./HerdView.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49d931ac",
  null
  
)

export default component.exports