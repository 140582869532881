import { render, staticRenderFns } from "./NavButtonGroup.vue?vue&type=template&id=53555dae&scoped=true&"
import script from "./NavButtonGroup.vue?vue&type=script&lang=js&"
export * from "./NavButtonGroup.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53555dae",
  null
  
)

export default component.exports